import { SET_ITEMS, SET_ITEMS_FIELDS } from '../actions';

const initialState = {
  item: {
    integrationDetail: {},
  },
  units: [],
  unit: {},
  showItemsImportStatus: false,
};

function items (state = initialState, action) {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ITEMS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default items;
