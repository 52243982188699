/* eslint-disable max-len */
class Urls {
  constructor () {
    const { REACT_APP_ENV, REACT_APP_LOCAL_SERVER, REACT_APP_API_SUB_DOMAIN = 'test' } = process.env; // eslint-disable-line
    this.devEnv = REACT_APP_ENV === 'development';
    this.isLocal = REACT_APP_LOCAL_SERVER === 'true';
    this.apiSubDomain = this.devEnv ? REACT_APP_API_SUB_DOMAIN : 'backend';
    this.fortnoxClientId = this.devEnv ? 'vGFzjLd59ldE' : 'TkR8G28HjG0N';
    this.fortnoxRedirectUrl = this.devEnv ? 'https://apptest.bokningoschema.se' : 'https://app3.bokningoschema.se';
    this.fortnoxIntegrationUrlState = 'f07n0x_au75_c063';
    this.fortnoxAppStoreIntegrationUrlState = 'fortnox_marketplace';
    this.vismaIntegrationUrlState = 'v12ma_au75_c063';
    this.ebokaIntegrationUrlState = 'e30ka';
    // eslint-disable-next-line no-nested-ternary
    this.ebokaClientId = this.isLocal ? 'ec6c02373d9fa6425c3c' : this.devEnv ? 'c83f3fb5f935dc2e1829' : '129e15ce8137b15c8304';
    this.googleClientId = '253625020463-s2n7rdd4rvooedli36lvmkg407bcjvpf.apps.googleusercontent.com';
  }

  getApiBaseUrl = () => (this.isLocal ? 'http://localhost:3000' : `https://${this.apiSubDomain}.bokningoschema.se`);

  getVismaIntegrationUrl = () => (this.devEnv
    ? `https://identity.vismaonline.com/connect/authorize?client_id=birdvisionabsandbox&redirect_uri=https://apptest.bokningoschema.se&scope=ea:api%20offline_access%20ea:sales&state=${this.vismaIntegrationUrlState}&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true` // eslint-disable-line
    : `https://identity.vismaonline.com/connect/authorize?client_id=birdvision&redirect_uri=https://app3.bokningoschema.se&scope=ea:api%20offline_access%20ea:sales&state=${this.vismaIntegrationUrlState}&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true`); // eslint-disable-line

  getFortnoxIntegrationUrl = (state = this.fortnoxIntegrationUrlState) => (this.devEnv
    ? `https://apps.fortnox.se/oauth-v1/auth?client_id=${this.fortnoxClientId}&redirect_uri=${this.fortnoxRedirectUrl}&scope=settings%20article%20bookkeeping%20companyinformation%20customer%20invoice%20print%20salary%20price%20payment%20inbox%20connectfile&state=${state}&access_type=offline&response_type=code` //eslint-disable-line
    : `https://apps.fortnox.se/oauth-v1/auth?client_id=${this.fortnoxClientId}&redirect_uri=${this.fortnoxRedirectUrl}&scope=settings%20article%20bookkeeping%20companyinformation%20customer%20invoice%20print%20salary%20price%20payment%20inbox%20connectfile&state=${state}&access_type=offline&response_type=code`); //eslint-disable-line

  getEbokaIntegrationUrl = () => (
    // eslint-disable-next-line no-nested-ternary
    this.isLocal
      ? `http://localtest.me:3008/se/a/integration/authorize?clientId=${this.ebokaClientId}&redirectUri=http://localhost:5001&state=${this.ebokaIntegrationUrlState}`
      : (
        this.devEnv
          ? `https://test-eboka.website/se/a/integration/authorize?clientId=${this.ebokaClientId}&redirectUri=https://apptest.bokningoschema.se&state=${this.ebokaIntegrationUrlState}`
          : `https://eboka.se/se/a/integration/authorize?clientId=${this.ebokaClientId}&redirectUri=https://app3.bokningoschema.se&state=${this.ebokaIntegrationUrlState}`
      )
  )

  appGuideUrl = () => 'https://bokningoschema.helpscoutdocs.com/'

  appGuideInvocingUrl = () => 'https://bokningoschema.helpscoutdocs.com/article/16-integration'

  appGuideBookingUrl = () => 'https://bokningoschema.helpscoutdocs.com/'

  getPrivacyPolicyPdfLink = () => 'https://bokningoschema.se/BokningoSchema_Online_Villkor.pdf';

  getViewCompanyInvoiceOnFortnoxUrl = () => 'https://minfaktura.fortnox.se/login';

  getAdminAppLoginSchemeUrl = (token, role) => `bokningoschema://login?token=${token}&role=${role}`;

  appRequestFeatureUrl = () => 'https://bokning-och-schema-online.canny.io/feature-requests/' // eslint-disable-line

  getHowToMigrateBookingToProjectVideoUrl = () => 'https://www.youtube.com/embed/WEhqdQLGeWI?si=BXPAWsg_jMrB3N86'

  getGoogleClientId = () => this.googleClientId;
}

export default new Urls();
