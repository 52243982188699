/* eslint-disable max-len */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { dndBackend, touchBackendOptions } from 'configs/react-dnd-backend';
import * as Sentry from '@sentry/react';
import theme from 'components/Theme';
import urls from 'utils/urls';
import './app.css';
import './configs';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './appRedux/store/index';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5d4dbbdb34deb42c1a935693d5874d1a@o331141.ingest.sentry.io/4505669097684992',
    integrations: [
      new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://backend.bokningoschema.se/api/'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        networkDetailAllowUrls: [urls.getApiBaseUrl()],
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.0,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: REACT_APP_ENV === 'production' ? 0.1 : 1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

const Main = () => (
  <DndProvider backend={dndBackend} options={touchBackendOptions}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </DndProvider>
);

root.render(<Main />);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: http://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
