import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { userSignOut } from 'appRedux/thunks/auth';
import { Typography, Grid, Box, Container } from 'components/library';
import { fm } from 'utils/string';

export default function NotAuthorized () {
  const dispatch = useDispatch();
  const onClickSignout = () => {
    dispatch(userSignOut());
  };

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="center">
        <Typography textAlign="center">
          <Box my={2}>
            {fm('not_permitted_to_view_message')}
          </Box>
          <Link to="/" onClick={onClickSignout}>
            {fm('back_to_login')}
          </Link>
        </Typography>
      </Grid>
    </Container>
  );
}
