import axios from 'utils/axios';
import { sentryCaptureException } from 'utils/sentry';

const axiosBaseQuery = () => async ({ url, method, data, params }) => {
  try {
    const result = await axios({
      url,
      method,
      data,
      params,
    });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;
    sentryCaptureException(err);
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export default axiosBaseQuery;
