import { SET_HISTORY_DATA, SET_HISTORY_FIELDS } from '../actions';

const initialState = { integrationErrors: [] };

function history (state = initialState, action) {
  switch (action.type) {
    case SET_HISTORY_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_HISTORY_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default history;
