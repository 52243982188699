export const names = {
  home: 'dashboard',
  bookings: 'bookings',
  unmigratedBookingsRecurrences: 'unmigratedBookingsRecurrences',
  orders: 'orders',
  orderActivity: 'orderActivity',
  projects: 'projects',
  projectActivity: 'projectActivity',
  projectOrders: 'projectOrders',
  projectItems: 'projectItems',
  proposals: 'proposals',
  proposalTemplates: 'proposalTemplates',
  proposalActivity: 'proposalActivity',
  bookingItems: 'bookingItems',
  customerRequestsPopover: 'customerRequestsPopover',
  orderCustomerRequestsPopover: 'orderCustomerRequestsPopover',
  recurringBookings: 'recurringBookings',
  orderCustomersRequests: 'orderCustomersRequests',
  recurringBookingModal: 'recurringBookingModal',
  bookingInProgressModal: 'bookingInProgressModal',
  bookingsReports: 'bookingsReports',
  employeesReports: 'employeesReports',
  employeeReports: 'employeeReports',
  employeeHourReportDrawer: 'employeeHourReportDrawer',
  employeeAvailability: 'employeeAvailability',
  customersReports: 'customersReports',
  invoicesReports: 'invoicesReports',
  fortnoxAccountsList: 'fortnoxAccountsList',
  calendar: 'calendar',
  bigCalendar: 'bigCalendar',
  invoices: 'invoices',
  invoicesNext: 'invoicesNext',
  reportsNext: 'reportsNext',
  reportsNextOrders: 'reportsNextOrders',
  reportsNextEmployees: 'reportsNextEmployees',
  invoicesNextHistory: 'invoicesNextHistory',
  history: 'history',
  integrationErrors: 'integrationErrors',
  invoiceHistory: 'invoiceHistory',
  timeManagement: 'timeManagement',
  timeManagementNext: 'timeManagementNext',
  timeManagementPunches: 'timeManagementPunches',
  timeManagementNextOverview: 'timeManagementNextOverview',
  timeManagementSalary: 'timeManagementSalary',
  timeManagementOverview: 'timeManagementOverview',
  timeManagementPayroll: 'timeManagementPayroll',
  timeManagementEmployeeBookingTimeSummary: 'timeManagementEmployeeBookingTimeSummary',
  timeManagementEmployeeInternalTimeSummary: 'timeManagementEmployeeInternalTimeSummary',
  timeManagementSalaryEmployee: 'timeManagementSalaryEmployee',
  timeReportList: 'timeReportList',
  otherWorkCodes: 'otherWorkCodes',
  requests: 'requests',
  activeCustomers: 'activeCustomers',
  keys: 'keys',
  skills: 'skills',
  customerLogs: 'customerLogs',
  inactiveCustomersList: 'inactiveCustomersList',
  customerLogin: 'customerLogin',
  items: 'items',
  services: 'services',
  userGroups: 'userGroups',
  weeklyWorkPlans: 'weeklyWorkPlans',
  timeWorkCodes: 'timeWorkCodes',
  overtime: 'overtime',
  notifications: 'notifications',
  companies: 'companies',
  redDays: 'redDays',
  fileManager: 'fileManager',
  reports: 'reports',
  smartScheduling: 'smartScheduling',
  customerDocuments: 'customerDocuments',
  employeeDocuments: 'employeeDocuments',
  allCustomersDocuments: 'allCustomersDocuments',
  allUsersDocuments: 'allUsersDocuments',
  openApi: 'openAPi',
  extendableBookings: 'extendableBookings',
  bookingProjectMigrationRecurringBookings: 'bookingProjectMigrationRecurringBookings',
  bookingProjectMigratedHistory: 'bookingProjectMigratedHistory',
  productUpdates: 'productUpdates',
  productUpdatesCompanies: 'productUpdatesCompanies',
  availableEmployees: 'availableEmployees',
  shiftRequests: 'shiftRequests',
  openShiftsEmployeesList: 'openShiftsEmployeesList',
  billingActiveUsers: 'billingActiveUsers',

  departments: 'departments',
  areas: 'areas',
  customerLocationMap: 'map',
  billingHistory: 'billingHistory',
  billingInvoiceHistory: 'billingInvoiceHistory',

  ebokaBookings: 'ebokaBookings',

  superAdminCompanies: 'superAdminCompanies',
  superAdminCompanySettings: 'superAdminCompanySettings',
  superAdminInvoices: 'superAdminInvoices',
  superAdminErrors: 'superAdminErrors',
  superAdminFrontendErrors: 'superAdminFrontEndErrors',
  superAdminUsers: 'superAdminUsers',
  superAdminSMS: 'superAdminSMS',
  superAdminProposalSignature: 'superAdminProposalSignature',
  superAdminCustomers: 'superAdminCustomers',
  superAdminItems: 'superAdminItems',
  superAdminBookingAutoExtendErrors: 'superAdminBookingAutoExtendErrors',
  superAdminBookingsInProgress: 'superAdminBookingsInProgress',
  superAdminBookingsFailed: 'superAdminBookingsFailed',
  superAdminOpenApiIntegration: 'superAdminOpenApiIntegration',
  superAdminWebAppUsers: 'superAdminWebAppUsers',

  activeUsers: 'activeUsers',
  inactiveUsersList: 'inactiveUsersList',
  employeeViewRequests: 'employeeViewRequests',
  bookingActivity: 'bookingActivity',
  employeeSchedules: 'employeeSchedules',
  employeeOpenShifts: 'employeeOpenShifts',
  comments: 'comments',
  conversations: 'conversations',
  superAdminPermissions: 'superAdminPermissions',
  smsSchedule: 'smsSchedule',
  smsHistory: 'smsHistory',
  superAdminMobileAppLoginHistory: 'superAdminMobileAppLoginHistory',
  punchTime: 'punchTime',
  employeeshiftTimes: 'employeeshiftTimes',
  customers: 'customers',
  users: 'users',
  userEmailHistory: 'userEmailHistory',
  usersLogs: 'usersLogs',
  tasks: 'tasks',
  storage: 'storage',
  predefinedChecklists: 'predefinedChecklists',
  timeManagementSummary: 'timeManagementSummary',
  companyInvoicesHistory: 'companyInvoicesHistory',
  superAdminInvoicesHistory: 'superAdminInvoicesHistory',
  superAdminStorage: 'superAdminStorage',
};
