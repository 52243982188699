import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fm } from 'utils/string';
import filterChipsConfig from 'components/FiltersCommon/filterChipsConfig';

export const bookingDaysSelectOptions = [
  { value: 'monday', label: <FormattedMessage id="monday" defaultMessage="Monday" /> },
  { value: 'tuesday', label: <FormattedMessage id="tuesday" defaultMessage="Tuesday" /> },
  { value: 'wednesday', label: <FormattedMessage id="wednesday" defaultMessage="Wednesday" /> },
  { value: 'thursday', label: <FormattedMessage id="thursday" defaultMessage="Thursday" /> },
  { value: 'friday', label: <FormattedMessage id="friday" defaultMessage="Friday" /> },
  { value: 'saturday', label: <FormattedMessage id="saturday" defaultMessage="Saturday" /> },
  { value: 'sunday', label: <FormattedMessage id="sunday" defaultMessage="Sunday" /> },
];

export const filtersData = [
  filterChipsConfig.startDate,
  filterChipsConfig.endDate,
  filterChipsConfig.customer,
  filterChipsConfig.employee,
  filterChipsConfig.service,
  filterChipsConfig.userActiveStatus,
  filterChipsConfig.multipleTags,
];

export const reportsTabTypes = {
  timeReport: 'timeReport',
  revenueReport: 'revenueReport',
  proposalReport: 'proposalReport',
};

export const internalTimeColors = {
  presence: '#165BAA',
  absence: '#B12F30',
};

export const reportTypes = {
  employee: 'employee',
  customer: 'customer',
  title: 'title',
  status: 'status',
};

export const reportTypeNames = [
  { label: fm('employee'), value: reportTypes.employee },
  { label: fm('customer'), value: reportTypes.customer },
  { label: fm('title'), value: reportTypes.title },
];

export const proposalReportTypeNames = [
  { label: fm('status'), value: reportTypes.status },
  { label: fm('title'), value: reportTypes.title },
];
