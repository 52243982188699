import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFormattedListData } from 'appRedux/utility';
import snakify from 'utils/snakify';
import { setSnackbarError, setSnackbarSuccess, setFilters } from 'appRedux/actions';
import { getFormattedSmsOrEmailRequestBody } from 'appRedux/owner/orders/selector';
import { get, isEmpty, omit } from 'utils/lodash';
import { fm } from 'utils/string';
import { orderStatus } from 'utils/enum';
import { validateAndGetTask } from 'appRedux/thunks/checklists/validator';
import {
  getFormattedProjectResponse,
  getFormattedPredefinedChecklist,
  getFormattedProjectRequestBody,
  getFormattedFilterParams,
  getFormattedProjectData,
  getFormattedProjectOrdersResponse,
  getFormattedFieldsToUpdate,
  getCopyProjectData,
  getFormattedSmsBodyForProject,
  getFormattedCustomerData,
  getFormattedProjectRecurringRulesRequestBody,
} from './selector';
import { requestUpdateProposalObjectCreated } from '../proposals/thunk';

export const requestProjectsStats = createAsyncThunk(
  'project/fetchProjectStats',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = omit(['order', 'orderBy'])(getFormattedFilterParams(filters));
      const response = await extra.axios.get('/api/v3/projects/project_stats', { params });
      const { areaStats, daysStats, customerOrganisationStats } = response.data.data;
      const daysArray = Object.keys(daysStats).map((day) => ({
        name: day,
        shortName: day.slice(0, 3),
        order: daysStats[day],
      }));
      const areasArray = Object.keys(areaStats).map((area) => ({
        name: area,
        value: areaStats[area],
        shortName: area.slice(0, 3),
      }));
      return {
        projectStats: {
          daysStats: daysArray,
          areaStats: areasArray,
          customerStats: getFormattedCustomerData(customerOrganisationStats),
        },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_stats_get_error'));
    }
  },
);

export const addPredefinedChecklistToProject = createAsyncThunk(
  'project/addList',
  async (data, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(
        '/api/v3/checklists/tasks',
        { params: { task_category_id: data.taskCategory.id } },
      );
      const { checklist } = getFormattedPredefinedChecklist(response.data.data);
      const { projectChecklists = [] } = data.project;
      const newProjectChecklists = [...projectChecklists, checklist];
      return { project: { ...data.project, projectChecklists: newProjectChecklists } };
    } catch (err) {
      dispatch(setSnackbarError('snackbarGetPredefinedChecklistTaskError'));
    }
  },
);

export const requestCreateProjectTag = createAsyncThunk(
  'projectl/createProjecttag',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { newTag, oldTags = [] } = data;
      const { projects } = getState();
      // check if the similar titled tag is already present
      const params = { query: newTag.title };
      const response = await extra.axios.get('/api/v3/tags/search', { params });
      const presentTags = Array.isArray(response.data.data) ? response.data.data : [];
      const presentData = presentTags.find((tag) => tag.title.toLowerCase() === newTag.title.toLowerCase());
      // if tag with title not present create new
      if (!presentData) {
        const reqBody = { tag: newTag };
        const respose = await extra.axios.post('/api/v3/tags', reqBody);
        data = { tagId: respose.data.data.id, title: respose.data.data.title };
        const projectTags = oldTags.map((tag) => (tag.title === newTag.title ? data : tag));
        return { project: { ...projects.project, projectTags } };
      }
      const tagData = { tagId: presentData.id, title: presentData.title };
      const projectTags = oldTags.map((tag) => (tag.title === newTag.title ? tagData : tag));
      const project = { ...projects.project, projectTags };
      return { project };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.tags.searchTags.error'));
    }
  },
);

export const requestProject = createAsyncThunk(
  'project/fetchProject',
  async (projectId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/projects/${projectId}`);
      const { project, projectChecklists, documents } = response.data.data;
      return {
        project: getFormattedProjectData({ ...project, projectChecklists }),
        projectDocuments: { records: documents },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_get_error'));
    }
  },
);

export const requestCreateProject = createAsyncThunk(
  'project/createProject',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { profile } = getState();
      const body = getFormattedProjectRequestBody(data.project, profile.company);
      const smsOrEmailBody = getFormattedSmsOrEmailRequestBody(data.project);
      const formData = new FormData();
      formData.append('project', JSON.stringify({ ...body }));
      if (data.project.proposalId) formData.append('proposal_id', data.project.proposalId);
      formData.append('sms_or_email_params', JSON.stringify({ ...smsOrEmailBody }));
      if (data.project.filesToUpload) {
        data.project.filesToUpload.forEach((file) => formData.append('documents[]', file));
      }
      if (data.project.documentIds) {
        formData.append('document_ids', JSON.stringify(data.documentIds));
      }
      const response = await extra.axios.post('/api/v3/projects', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { project } = response.data.data;
      dispatch(requestProject(project.id));
      dispatch(setSnackbarSuccess('snackbar_project_created'));
      if (data.project.proposalId) {
        dispatch(requestUpdateProposalObjectCreated({ proposalId: data.proposalId }));
      }
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_create_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateProject = createAsyncThunk(
  'project/updateProject',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { profile } = getState();
      const body = getFormattedProjectRequestBody(data.project, profile.company);
      const formData = new FormData();
      formData.append('project', JSON.stringify({ ...body }));
      if (data.project.filesToUpload) {
        for (const file of data.project.filesToUpload) { // eslint-disable-line
          formData.append('documents[]', file);
        }
      }
      if (data.project.documentIds) {
        formData.append('document_ids', JSON.stringify(data.project.documentIds));
      }
      if (data.project.fieldsToUpdate) {
        formData.append('fields_to_update', JSON.stringify(getFormattedFieldsToUpdate(data.project.fieldsToUpdate)) ?? {});
      }
      const response = await extra.axios.put(`/api/v3/projects/${data.project.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(requestProject(data.project.id));
      dispatch(setSnackbarSuccess('snackbar_project_updated'));

      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestProjects = createAsyncThunk(
  'project/fetchProjects',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedFilterParams(filters);
      const response = await extra.axios.get('/api/v3/projects', { params });
      if (!response.data.data.projects) {
        dispatch(setFilters({ page: 0, pages: 0, totalRecords: 0, name }));
        return { records: [] };
      }
      const { records, ...nextFilters } = getFormattedProjectResponse(response.data.data.projects);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_projects_fetch_error'));
    }
  },
);

export const requestProjectOrders = createAsyncThunk(
  'project/fetchProjectOrders',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(data.name, getState);
      const params = getFormattedFilterParams(filters);
      const response = await extra.axios.get(`/api/v3/projects/${data.id}/project_orders`, { params });
      const { orders } = response.data.data;
      const { records, ...nextFilters } = getFormattedProjectOrdersResponse(orders);
      dispatch(setFilters({ ...nextFilters, name: data.name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_orders_get_error'));
    }
  },
);

export const requestDeleteProjects = createAsyncThunk(
  'project/deleteProjects',
  async (ids, { extra, dispatch }) => {
    try {
      const params = { projects: ids };
      await extra.axios.delete('/api/v3/projects/bulk_delete', { data: params });
      dispatch(setSnackbarSuccess('projects_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_projects_delete_error'));
    }
  },
);

export const requestDeleteProject = createAsyncThunk(
  'project/deleteProject',
  async (id, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/projects/${id}`);
      dispatch(setSnackbarSuccess('project_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_delete_error'));
    }
  },
);

export const requestDeleteProjectDocument = createAsyncThunk(
  'project/deleteDocument',
  async (ids, { extra, dispatch, getState }) => {
    const { projects: { projectDocuments: { records } } } = getState();
    try {
      const params = { document_id: ids.documentId };
      await extra.axios.delete(`/api/v3/projects/${ids.projectId}/delete_document`, { params });
      dispatch(setSnackbarSuccess('document_deleted_successfully'));
      return {
        projectDocuments: { records: records.filter((record) => record.id !== ids.documentId) },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_document_delete_error'));
    }
  },
);

export const requestProjectToCopy = createAsyncThunk(
  'project/copyProject',
  async (projectId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/projects/${projectId}`);
      const { project, projectChecklists, documents } = response.data.data;
      return {
        project: getCopyProjectData({ ...project, projectChecklists }),
        projectDocuments: { records: (documents || []).map((doc) => ({ ...doc, id: undefined })) },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_copy_error'));
    }
  },
);

export const requestReactivateProject = createAsyncThunk(
  'project/reactivateProject',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        start_date: data.startDate,
        end_date: data.endDate,
      };
      await extra.axios.put(`/api/v3/projects/${data.id}/reactivate_project`, params);
      dispatch(setSnackbarSuccess('projects_reactivate_successfully'));
      dispatch(requestProject(data.id));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_activate_error'));
    }
  },
);

export const requestCloseProject = createAsyncThunk(
  'project/closeProject',
  async (data, { extra, dispatch }) => {
    try {
      const params = { end_date: data.endDate };
      await extra.axios.put(`/api/v3/projects/${data.id}/close_project`, params);
      dispatch(setSnackbarSuccess('project_closed_message'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_close_error'));
    }
  },
);

export const requestProjectActivity = createAsyncThunk(
  'project/activities',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(data.name, getState);
      const params = getFormattedFilterParams(filters);
      const response = await extra.axios.get(`/api/v3/projects/${data.id}/activity`, { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data.activities);
      dispatch(setFilters({ ...nextFilters, name: data.name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_projects_activities_error'));
    }
  },
);

export const requestSendProjectEmail = createAsyncThunk(
  'project/sendEmail',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        personal_message: data.fieldsToInclude.personalMessage ? data.personalMessage : undefined,
        fields_to_include: snakify(data.fieldsToInclude),
      };
      await extra.axios.post(`/api/v3/projects/${data.id}/send_email_to_customer`, params);
      dispatch(setSnackbarSuccess('project_email_sent'));
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(
          fm(get('response.data.errors[0].details.error_message')(err),
            'Customer email is missing. Add email in customer and try again.'),
        ));
      } else {
        dispatch(setSnackbarError('snackbar_project_send_email_error'));
      }
    }
  },
);

export const requestSendProjectSms = createAsyncThunk(
  'project/sendSms',
  async (data, { extra, dispatch }) => {
    try {
      const params = getFormattedSmsBodyForProject(data);
      await extra.axios.post(`/api/v3/projects/${data.projectId}/send_sms`, params);
      dispatch(setSnackbarSuccess('project_sms_sent'));
      dispatch(requestProject(data.projectId));
    } catch (err) {
      if (get('response.data.errors[0].details.code')(err) === '23001') {
        dispatch(setSnackbarError('23001'));
      } else {
        dispatch(setSnackbarError('snackbar_project_sms_error'));
      }
    }
  },
);

export const requestSendProjectTestEmail = createAsyncThunk(
  'project/sendTestEmail',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        email_address: data.emailAddress,
        fields_to_include: snakify(data.fieldsToInclude),
        personal_message: data.fieldsToInclude.personalMessage ? data.personalMessage : undefined,
      };
      await extra.axios.post(`/api/v3/projects/${data.id}/send_test_email`, params);
      dispatch(setSnackbarSuccess('project_test_email_sent'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_test_email_project_error'));
    }
  },
);

export const requestCreateChecklistTask = createAsyncThunk(
  'project/createTask',
  async (data, { extra, dispatch }) => {
    try {
      const body = await validateAndGetTask(data.newTask);
      const response = await extra.axios.post('/api/v3/tasks', body);
      const { id, name } = response.data.data;
      const taskData = { id, title: name };
      data.setEditedTaskData(taskData);
    } catch (err) {
      dispatch(setSnackbarError('snackbar_task_error'));
    }
  },
);

export const requestProjectSmsEmailHistory = createAsyncThunk(
  'project/fetchProjectSmsHistory',
  async (projectId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/projects/${projectId}/customer_message_histories`);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_projects_fetch_error'));
    }
  },
);

export const requestUpdateRecurringOptionsForProject = createAsyncThunk(
  'project/updateRecurringOptionsForProject',
  async (data, { extra, dispatch }) => {
    try {
      const params = getFormattedProjectRecurringRulesRequestBody(data);
      await extra.axios.put(`/api/v3/projects/${data.id}/update_project_orders`, params);
      dispatch(setSnackbarSuccess('projects_recurring_rules_updated_successfully'));
      dispatch(requestProject(data.id));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_recurring_rule_error'));
    }
  },
);

export const requestCheckProjectOrdersOnUpdateRecurringRules = createAsyncThunk(
  'project/fetchProjectOrdersOnUpdateRecurringRules',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        page: 1,
        per_page: 10,
        start_date: data.updatedStartDate,
        end_date: data.startDate,
        statuses: [orderStatus.completed, orderStatus.cancelled, orderStatus.freeze],
      };
      const response = await extra.axios.get(`/api/v3/projects/${data.id}/project_orders`, { params });
      const { orders } = response.data.data;
      if (!isEmpty(orders.records)) {
        return { nonActiveOrdersPresent: true };
      }
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_orders_get_error'));
    }
  },
);
